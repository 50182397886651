.mobile-header, .tracks-mobile-header {
  display: none; }
  @media only screen and (max-width: 479px) {
    .mobile-header, .tracks-mobile-header {
      display: block; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .mobile-header, .tracks-mobile-header {
      display: block; } }

.dropdown-item img {
  width: 1.2rem;
  margin-right: .7rem; }

.dropdown-item .description {
  font-size: 80%; }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #F0F0F0;
  color: #888; }

h1 {
  color: #F26522;
  margin: 0px;
  text-align: left;
  font-size: 1.5rem;
  display: block; }

h2 {
  color: #F26522;
  margin: .1em;
  text-align: left;
  font-size: 1.3rem;
  font-weight: 500;
  display: block; }

h3 {
  margin: .1em;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 500;
  display: block; }

a {
  cursor: pointer; }

.loading-site-root {
  padding-top: 20rem;
  width: 100%;
  text-align: center; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .content-root {
    height: 100vh; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .content-root nav.navbar {
    flex-shrink: 0 !important; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .content-root main {
    flex: 1 0 auto !important; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .content-root footer {
    flex-shrink: 0 !important; } }

.container-main {
  width: 100%;
  text-align: center; }
  .container-main .container-content-wrap {
    margin-right: 1rem;
    margin-left: 1rem; }
    .container-main .container-content-wrap .container-content, .container-main .container-content-wrap .tracks-container {
      margin-top: 2rem;
      max-width: 1160px;
      margin-right: auto;
      margin-left: auto; }

.container-frame, .landing-root .landing-content, .tracks-container {
  overflow: hidden;
  border-color: #EDEDED;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 19px;
  margin-bottom: 14px; }
  .container-frame hr, .landing-root .landing-content hr, .tracks-container hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #EDEDED;
    margin: 1em 0;
    padding: 0; }

.overflown-section {
  overflow: unset; }

.main-container {
  position: relative;
  padding-top: 2rem;
  max-width: 1160px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 1em; }
  .main-container .DraftEditor-editorContainer {
    height: 300px; }
  .main-container h5 {
    color: #888; }
    .main-container h5 a {
      display: inline-block;
      color: #888; }
  .main-container h4 {
    margin-top: 1rem;
    color: black; }
    .main-container h4 a {
      display: inline-block;
      color: black; }
  .main-container .vertical-buttons {
    display: flex;
    flex-direction: column; }
    .main-container .vertical-buttons button {
      width: 100%;
      margin-top: 1rem; }
  .main-container .button-like-text {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
    cursor: initial; }
  .main-container .page__logo {
    width: 100%;
    max-width: 420px;
    border-radius: 4px; }
    @media only screen and (max-width: 768px) {
      .main-container .page__logo {
        max-width: 75%;
        width: auto;
        margin: auto; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .main-container .page__logo {
        max-width: 75%;
        width: auto;
        margin: auto; } }
  .main-container .page__country img {
    width: 1.2rem;
    margin-right: .7rem; }
  .main-container .page__socials {
    display: block; }
    .main-container .page__socials a {
      margin: 0.3rem;
      display: inline-block;
      font-size: 1.5rem;
      color: #888; }
  .main-container h4 a:hover,
  .main-container h5 a:hover,
  .main-container .page__socials a:hover {
    color: #F26522; }
  .main-container .page__about {
    margin-top: 1em; }
  .main-container .badge {
    margin-right: .5em; }
  .main-container .badge.visibility.private {
    background: #337ab7;
    color: #fff; }
  .main-container .badge.official {
    background: #267F00;
    color: #fff; }
  .main-container .badge.virtual {
    background: #F26522;
    color: #fff; }
  .main-container .badge.real {
    background: #c72222;
    color: #fff; }
  .main-container .badge-like {
    font-size: 13px; }
  .main-container .avatar {
    display: inline-block;
    line-height: 1.575rem;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    -webkit-border-radius: 0.75rem;
    -moz-border-radius: 0.75rem;
    border-radius: 0.75rem;
    text-align: center;
    color: #fff; }
  .main-container .avatar-text {
    margin-left: .3rem; }
  .main-container .more__link {
    font-size: small;
    margin-top: 0rem;
    margin-bottom: .3rem;
    cursor: pointer;
    color: #F26522;
    float: right; }
  .main-container .clearfix::after {
    content: "";
    clear: both;
    display: table; }
  .main-container .tooltipped::before {
    content: attr(data-tip-text);
    font-size: 10px;
    position: absolute;
    z-index: 999;
    white-space: nowrap;
    background: #fff;
    border: 1px solid #222429;
    color: #888;
    padding: 2px;
    border-radius: 2px;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    transform: translateY(-8000px); }
  .main-container .tooltipped:hover::before {
    opacity: 1;
    transform: translateY(-8px); }
  .main-container .error-message, .main-container .tracks-container .tracks-error, .tracks-container .main-container .tracks-error {
    background-color: lightcoral;
    margin: .5em;
    color: #fff; }
  .main-container .loading-message, .main-container .tracks-container .tracks-loading, .tracks-container .main-container .tracks-loading {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bdbdbd; }
  .main-container .raw-price {
    font-size: 100%;
    text-decoration: line-through;
    font-weight: 500;
    margin: .2em;
    margin-left: 12px; }
  .main-container .discount-price {
    font-size: 130%;
    color: red;
    font-weight: 600;
    margin: .4em;
    margin-left: 12px; }

.react-select-container .react-select__menu {
  z-index: 20; }

.page-summary .btn {
  width: 100%;
  margin: .2em; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-summary {
    text-align: center; } }

@media only screen and (max-width: 768px) {
  .page-summary {
    text-align: center; } }

.error-message, .tracks-container .tracks-error {
  background-color: lightcoral;
  color: white;
  text-align: center;
  padding: 0.5rem; }

.loading-message, .tracks-container .tracks-loading {
  font-size: 80%;
  padding: 0.5rem;
  display: block; }

.load-more-message, .tracks-container .tracks-load-more {
  cursor: pointer;
  font-size: 80%;
  padding: 0.5rem;
  display: block; }

.filters, .tracks-filter-container {
  background: #F26522;
  color: #fff;
  padding: 1rem;
  text-align: left; }

.list-table, .tracks-container .tracks-table {
  margin: auto;
  width: 100%; }
  @media only screen and (max-width: 479px) {
    .list-table, .tracks-container .tracks-table {
      width: inherit; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .list-table, .tracks-container .tracks-table {
      width: inherit; } }

.header-cell, .tracks-container .tracks-table thead th {
  background-color: #222429;
  color: #fff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.common-cell, .tracks-container .tracks-table td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }
  @media only screen and (max-width: 479px) {
    .common-cell, .tracks-container .tracks-table td {
      display: none; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .common-cell, .tracks-container .tracks-table td {
      display: none; } }

.common-row, .tracks-container .tracks-table tr {
  cursor: pointer; }
  @media only screen and (max-width: 479px) {
    .common-row, .tracks-container .tracks-table tr {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .common-row, .tracks-container .tracks-table tr {
      padding-left: 0.5rem;
      padding-right: 0.5rem; } }

.common-row:hover, .tracks-container .tracks-table tr:hover {
  background: lightgray; }

.mobile-cell-header, .tracks-container .tracks-table thead th.tracks-mobile-cell-header {
  display: none; }
  @media only screen and (max-width: 479px) {
    .mobile-cell-header, .tracks-container .tracks-table thead th.tracks-mobile-cell-header {
      display: table-cell; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .mobile-cell-header, .tracks-container .tracks-table thead th.tracks-mobile-cell-header {
      display: table-cell; } }

.mobile-cell-content, .tracks-container .tracks-table td.tracks-mobile-cell-content {
  display: none;
  width: 100%;
  text-align: left; }
  @media only screen and (max-width: 479px) {
    .mobile-cell-content, .tracks-container .tracks-table td.tracks-mobile-cell-content {
      display: table-cell; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .mobile-cell-content, .tracks-container .tracks-table td.tracks-mobile-cell-content {
      display: table-cell; } }

.app-root .badge {
  color: #555555;
  background-color: #dddddd;
  font-weight: 400; }

.app-root .dropdown-menu a {
  cursor: pointer; }

.app-root .btn-join {
  background-color: #fff;
  color: #5cb85c;
  border-color: #4cae4c;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid;
  border-radius: 4px; }

.app-root table.table {
  width: 100% !important; }

.app-root input[type=checkbox].form-control {
  width: 1.5em;
  box-shadow: none; }

.app-root .navbar.fixed .btn-group {
  margin: 1px 0px; }

.app-root .app-root a.btn-default {
  color: #888; }

.app-root .app-root a.btn-primary {
  color: #fff; }

.app-root .panel-heading a {
  color: inherit;
  font-weight: 600; }

.app-root .panel-heading a:hover {
  text-decoration: none; }

.app-root a.btn-success {
  color: white; }

.app-root a {
  text-decoration: none;
  color: #F26522; }

.app-root .content-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .app-root .content-root .app-content {
    flex: 1;
    margin-top: 3px; }

.bingmap-widget-root .bingmap-widget-map {
  min-height: 8rem; }
  .bingmap-widget-root .bingmap-widget-map img.bingmap-widget-map-img {
    max-width: 100%;
    margin: auto; }

.description-text {
  text-align: justify; }

.description-logo {
  float: right;
  margin-left: 1rem;
  margin-bottom: 1rem; }

.table {
  width: 18rem; }

.dropzone-with-preview {
  width: 128px;
  height: 128px;
  border-width: 2px;
  border-color: #666666;
  border-style: dashed;
  border-radius: 5px;
  cursor: pointer; }
  .dropzone-with-preview .dropzone-with-preview_stub {
    text-align: center;
    margin-top: 25%;
    font-size: smaller; }
  .dropzone-with-preview .dropzone-with-preview_img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.dropzone-avatar-preview {
  margin: 0.3rem; }
  .dropzone-avatar-preview .avatar {
    display: inline-block;
    line-height: 1.575rem;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    -webkit-border-radius: 0.75rem;
    -moz-border-radius: 0.75rem;
    border-radius: 0.75rem;
    text-align: center;
    color: #fff; }

footer.app-footer {
  width: 100%;
  color: #888;
  position: relative; }
  footer.app-footer .app-footer__bottom-logo {
    height: 6rem;
    position: absolute;
    bottom: 0px;
    display: none; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      footer.app-footer .app-footer__bottom-logo {
        display: block; } }
    @media only screen and (min-width: 1200px) {
      footer.app-footer .app-footer__bottom-logo {
        display: block; } }
  footer.app-footer .app-footer__bottom-logo2 {
    position: absolute;
    bottom: 0px;
    display: none;
    left: 13rem; }
    @media only screen and (min-width: 1200px) {
      footer.app-footer .app-footer__bottom-logo2 {
        display: block; } }
  footer.app-footer .app-footer-strip1 {
    background-color: #F26522;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: right;
    min-height: 1rem; }
    footer.app-footer .app-footer-strip1 .app-footer__app-ver {
      display: inline-block;
      background: rgba(0, 0, 0, 0.5); }
  footer.app-footer .app-footer-strip2 {
    background-color: #222429;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: visible;
    display: flex; }
    footer.app-footer .app-footer-strip2 .app-footer-socials-container {
      height: 100%;
      flex-direction: column;
      display: flex;
      padding-bottom: .2em; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        footer.app-footer .app-footer-strip2 .app-footer-socials-container {
          height: auto;
          width: 100%;
          flex-direction: row; } }
      @media only screen and (min-width: 480px) and (max-width: 767px) {
        footer.app-footer .app-footer-strip2 .app-footer-socials-container {
          height: auto;
          width: auto;
          flex-direction: column; } }
      @media only screen and (max-width: 479px) {
        footer.app-footer .app-footer-strip2 .app-footer-socials-container {
          height: auto;
          width: auto;
          flex-direction: column; } }
    footer.app-footer .app-footer-strip2 .app-footer__socials {
      text-align: right;
      flex: 1; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        footer.app-footer .app-footer-strip2 .app-footer__socials {
          text-align: left; } }
      @media only screen and (min-width: 480px) and (max-width: 767px) {
        footer.app-footer .app-footer-strip2 .app-footer__socials {
          text-align: center; } }
      @media only screen and (max-width: 479px) {
        footer.app-footer .app-footer-strip2 .app-footer__socials {
          text-align: center; } }
      footer.app-footer .app-footer-strip2 .app-footer__socials a {
        color: #888;
        margin: 0.3rem;
        margin-right: .5rem;
        display: inline-block;
        font-size: 1.5rem; }
      footer.app-footer .app-footer-strip2 .app-footer__socials a:hover,
      footer.app-footer .app-footer-strip2 .app-footer__socials a:focus {
        color: #888; }
    footer.app-footer .app-footer-strip2 .app-footer__copy {
      text-align: right;
      font-size: 90%;
      align-self: flex-end;
      margin-right: 0.5rem; }
      @media only screen and (min-width: 480px) and (max-width: 767px) {
        footer.app-footer .app-footer-strip2 .app-footer__copy {
          align-self: center; } }
      @media only screen and (max-width: 479px) {
        footer.app-footer .app-footer-strip2 .app-footer__copy {
          align-self: center; } }
  footer.app-footer ul {
    list-style: none;
    margin-top: 0.1em;
    padding-left: 0px;
    padding-right: 0px; }
    footer.app-footer ul li a {
      padding: 0.1em;
      color: #888; }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      footer.app-footer ul li {
        text-align: center; } }
    @media only screen and (max-width: 479px) {
      footer.app-footer ul li {
        text-align: center; } }
    footer.app-footer ul li.title {
      font-weight: 700;
      margin-bottom: 0.2em;
      margin-top: .7rem; }

@media only screen and (min-width: 1200px) {
  .navbar .home {
    display: none; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar .container {
    width: 100%;
    padding-right: 3rem;
    padding-left: 3rem; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .container {
    width: 100%;
    padding-right: 2.8rem;
    padding-left: 2.8rem; } }

.navbar .navbar-header {
  min-height: 59px;
  background-repeat: no-repeat; }
  @media only screen and (max-width: 768px) {
    .navbar .navbar-header {
      background-image: url("../images/header_logo.jpg"); } }
  .navbar .navbar-header img {
    cursor: pointer; }
  .navbar .navbar-header .navbar-brand {
    padding: 0;
    height: 59px;
    max-width: 100%; }
    @media only screen and (max-width: 768px) {
      .navbar .navbar-header .navbar-brand {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .navbar .navbar-header .navbar-brand {
        display: none; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .navbar .navbar-header .navbar-brand {
        display: none; } }

@media only screen and (max-width: 768px) {
  .navbar .navbar-header.premium {
    background-image: url("../images/header_logo_premium.jpg"); } }

.navbar .sign-in .signin__icon {
  padding-right: .1rem;
  font-size: 120%; }

.navbar .sign-in .signin__text::after {
  content: "Sign in / Sign up"; }

@media only screen and (max-width: 768px) {
  .navbar .sign-in li {
    width: 100%;
    text-align: center; } }

.navbar.navbar-inverse {
  background-image: url("../images/bg_header_repeat.png");
  background-repeat: repeat-x;
  border-radius: 0px;
  border-width: 0px;
  margin-bottom: 0px; }
  .navbar.navbar-inverse .navbar-nav > li > a {
    font-weight: 600;
    color: #fff;
    padding-top: 18px;
    padding-bottom: 0px; }
    .navbar.navbar-inverse .navbar-nav > li > a:hover, .navbar.navbar-inverse .navbar-nav > li > a:focus {
      color: #F26522; }
  .navbar.navbar-inverse .navbar-nav > .active > a {
    color: #F26522;
    background-color: transparent; }
    .navbar.navbar-inverse .navbar-nav > .active > a:hover, .navbar.navbar-inverse .navbar-nav > .active > a:focus {
      color: #F26522;
      background-color: transparent; }
    .navbar.navbar-inverse .navbar-nav > .active > a div {
      padding-bottom: 6px;
      border-bottom-color: #F26522;
      border-bottom-style: solid;
      border-bottom-width: 4px; }

.navbar-nav .nav-dropdown-mainmenu.dropdown.open .dropdown-toggle {
  background-color: transparent;
  color: #F26522; }

.navbar-nav .nav-dropdown-mainmenu.dropdown .dropdown-toggle {
  background-color: transparent; }

.navbar-nav .nav-dropdown-mainmenu.active .toggle {
  border-bottom-color: #F26522;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  padding-bottom: .5em; }

.navbar-nav .nav-dropdown-mainmenu,
.navbar-nav .nav-dropdown-mainmenu.active.open {
  border-bottom: none; }
  .navbar-nav .nav-dropdown-mainmenu .dropdown-menu,
  .navbar-nav .nav-dropdown-mainmenu.active.open .dropdown-menu {
    background: #222429;
    border-radius: .3rem;
    margin: 0px; }
    .navbar-nav .nav-dropdown-mainmenu .dropdown-menu a,
    .navbar-nav .nav-dropdown-mainmenu.active.open .dropdown-menu a {
      padding-top: initial;
      padding-top: 11px;
      color: #fff;
      font-weight: 600;
      background-color: transparent;
      outline: none !important; }
    .navbar-nav .nav-dropdown-mainmenu .dropdown-menu a:hover,
    .navbar-nav .nav-dropdown-mainmenu.active.open .dropdown-menu a:hover {
      color: #F26522;
      background-color: transparent; }
  .navbar-nav .nav-dropdown-mainmenu span.fa,
  .navbar-nav .nav-dropdown-mainmenu.active.open span.fa {
    min-width: 1.2em;
    text-align: center; }

#nav_profile .dropdown-toggle,
#nav_cart .dropdown-toggle,
#nav_add .dropdown-toggle,
#nav_admin .dropdown-toggle,
#nav_notifications .dropdown-toggle {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

#nav_profile .divider,
#nav_cart .divider,
#nav_add .divider,
#nav_admin .divider,
#nav_notifications .divider {
  background-color: #444651; }

#nav_profile .dropdown-header,
#nav_cart .dropdown-header,
#nav_add .dropdown-header,
#nav_admin .dropdown-header,
#nav_notifications .dropdown-header {
  color: #fff;
  font-weight: 500;
  font-size: 14px; }

#nav_profile .dropdown.open .dropdown-toggle,
#nav_cart .dropdown.open .dropdown-toggle,
#nav_add .dropdown.open .dropdown-toggle,
#nav_admin .dropdown.open .dropdown-toggle,
#nav_notifications .dropdown.open .dropdown-toggle {
  color: #F26522; }

#nav_profile .dropdown a,
#nav_cart .dropdown a,
#nav_add .dropdown a,
#nav_admin .dropdown a,
#nav_notifications .dropdown a {
  padding-top: 11px;
  color: #fff;
  font-weight: 600;
  background-color: transparent;
  outline: none !important; }

#nav_profile .dropdown a:hover,
#nav_cart .dropdown a:hover,
#nav_add .dropdown a:hover,
#nav_admin .dropdown a:hover,
#nav_notifications .dropdown a:hover {
  color: #F26522; }

#nav_profile .dropdown .dropdown-menu,
#nav_cart .dropdown .dropdown-menu,
#nav_add .dropdown .dropdown-menu,
#nav_admin .dropdown .dropdown-menu,
#nav_notifications .dropdown .dropdown-menu {
  background: #222429;
  border-radius: .3rem;
  margin-top: .5rem; }
  #nav_profile .dropdown .dropdown-menu a,
  #nav_cart .dropdown .dropdown-menu a,
  #nav_add .dropdown .dropdown-menu a,
  #nav_admin .dropdown .dropdown-menu a,
  #nav_notifications .dropdown .dropdown-menu a {
    padding-top: initial; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #nav_profile .dropdown .dropdown-menu::before,
  #nav_cart .dropdown .dropdown-menu::before,
  #nav_add .dropdown .dropdown-menu::before,
  #nav_admin .dropdown .dropdown-menu::before,
  #nav_notifications .dropdown .dropdown-menu::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    right: 1.5rem;
    border-width: 0 .4rem .4rem .4rem;
    border-style: solid;
    border-color: #222429 transparent; } }

#nav_profile .dropdown span.fa,
#nav_cart .dropdown span.fa,
#nav_add .dropdown span.fa,
#nav_admin .dropdown span.fa,
#nav_notifications .dropdown span.fa {
  min-width: 1.2em;
  text-align: center; }

#nav_profile .avatar,
#nav_cart .avatar,
#nav_add .avatar,
#nav_admin .avatar,
#nav_notifications .avatar {
  display: inline-block;
  line-height: 1.575rem;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  border-radius: 0.75rem;
  text-align: center;
  color: #fff; }

#nav_profile .avatar-text,
#nav_cart .avatar-text,
#nav_add .avatar-text,
#nav_admin .avatar-text,
#nav_notifications .avatar-text {
  display: inline-block;
  line-height: 1.575rem;
  width: 1.5rem;
  height: 1.5rem;
  background: #F26522;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  border-radius: 0.75rem;
  text-align: center;
  color: #fff; }

#nav_profile .badge,
#nav_cart .badge,
#nav_add .badge,
#nav_admin .badge,
#nav_notifications .badge {
  min-width: .3rem;
  font-size: .5rem;
  padding: 3px 5px;
  position: relative;
  margin-left: -10px;
  margin-top: -16px;
  color: #fff;
  background-color: #337ab7; }

#nav_profile #gopremium-link,
#nav_cart #gopremium-link,
#nav_add #gopremium-link,
#nav_admin #gopremium-link,
#nav_notifications #gopremium-link {
  color: yellow; }

#nav_cart span.fa {
  min-width: 1.2em;
  text-align: center;
  font-size: 1rem; }

#sendowl-cart-widget span.fa {
  min-width: 1.2em;
  text-align: center;
  font-size: 1rem; }

#sendowl-cart-count {
  min-width: .3rem;
  font-size: .5rem;
  padding: 3px 5px;
  position: relative;
  margin-left: -10px;
  margin-top: -16px;
  color: #fff;
  background-color: #337ab7;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
  font-weight: 400;
  display: inline-block; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #nav_add .dropdown .dropdown-menu::before,
  #nav_notifications .dropdown .dropdown-menu::before,
  #nav_admin .dropdown .dropdown-menu::before {
    right: 1.3rem !important; } }

#nav_add .dropdown a.dropdown-toggle,
#nav_notifications .dropdown a.dropdown-toggle,
#nav_admin .dropdown a.dropdown-toggle {
  padding-top: 18px !important; }

#nav_add .toggle,
#nav_notifications .toggle,
#nav_admin .toggle {
  display: inline-block;
  text-align: center;
  font-size: 1rem; }

#nav_add .caret,
#nav_notifications .caret,
#nav_admin .caret {
  margin-top: -.4rem; }

.List {
  width: 100%; }

.list-footer {
  margin-top: .5rem; }
  .list-footer .list-legend {
    margin-right: 1rem;
    font-size: 85%; }

.rowentitylist {
  outline: none; }
  .rowentitylist .list-loading {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bdbdbd; }
  .rowentitylist .list-noRows {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #bdbdbd; }
  .rowentitylist .list-highlight-text {
    color: #F26522; }
  .rowentitylist .list-row,
  .rowentitylist .list-header {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .rowentitylist .list-row .list-row__decorator,
    .rowentitylist .list-header .list-row__decorator {
      color: #F26522;
      position: absolute;
      left: 0px; }
  .rowentitylist .list-row.read .list-message .list-name {
    font-weight: normal; }
  .rowentitylist .list-header {
    padding-top: .5rem;
    padding-bottom: .5rem; }
  .rowentitylist .list-row:hover {
    background: rgba(0, 0, 0, 0.1); }
  .rowentitylist .list-row {
    cursor: pointer;
    padding: 0 25px; }
    .rowentitylist .list-row .simbadge img {
      max-height: 12px; }
    .rowentitylist .list-row .list-row-content {
      align-items: center;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      height: 100%;
      overflow: hidden;
      padding-right: 3px; }
    .rowentitylist .list-row .list-row-number {
      font-size: 1rem;
      min-width: 1.8rem; }
      .rowentitylist .list-row .list-row-number .fa {
        margin-left: -.15em; }
    .rowentitylist .list-row .list-avatar {
      display: inline-block;
      line-height: 42px;
      width: 40px;
      height: 40px;
      background: white;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      text-align: center;
      text-align: center;
      margin-right: 20px; }
    .rowentitylist .list-row .list-message {
      flex-grow: 1;
      color: #444;
      text-overflow: ellipsis;
      overflow: hidden; }
      .rowentitylist .list-row .list-message .list-info-value {
        margin-right: .5em; }
      .rowentitylist .list-row .list-message .listrow-start {
        flex: 1; }
      .rowentitylist .list-row .list-message .price {
        font-size: 110%;
        font-weight: 600;
        margin: .4em .4em .6em .4em; }
      .rowentitylist .list-row .list-message .raw-price {
        font-size: 100%;
        text-decoration: line-through;
        font-weight: 500;
        margin: .2em; }
      .rowentitylist .list-row .list-message .discount-price {
        font-size: 130%;
        color: red;
        font-weight: 600;
        margin: .4em; }
      .rowentitylist .list-row .list-message .list-name {
        font-weight: bold;
        margin-bottom: 2px;
        font-size: 1em;
        line-height: 1.2em;
        max-height: 2.5em;
        overflow: hidden;
        display: flex; }
        .rowentitylist .list-row .list-message .list-name a {
          color: inherit; }
        .rowentitylist .list-row .list-message .list-name a:hover,
        .rowentitylist .list-row .list-message .list-name a:focus {
          color: #F26522; }
        .rowentitylist .list-row .list-message .list-name .badge,
        .rowentitylist .list-row .list-message .list-name .simbadge {
          float: right; }
      .rowentitylist .list-row .list-message .list-index {
        font-size: .8em;
        line-height: 1em;
        height: 1em;
        overflow: hidden;
        color: #444; }
        .rowentitylist .list-row .list-message .list-index a {
          color: inherit;
          margin: 0em 0.3em; }
        .rowentitylist .list-row .list-message .list-index a:hover,
        .rowentitylist .list-row .list-message .list-index a:focus {
          color: #F26522; }
      .rowentitylist .list-row .list-message .badge {
        padding: 1px 6px;
        margin-bottom: 2px;
        font-size: .85em; }
    .rowentitylist .list-row .list-buttons {
      display: inline-flex;
      justify-content: flex-end; }
      .rowentitylist .list-row .list-buttons .btn,
      .rowentitylist .list-row .list-buttons .btn-join {
        min-width: 5rem; }
    .rowentitylist .list-row .handle {
      font-weight: normal;
      font-family: Arial, Helvetica, sans-serif;
      margin-left: 1em; }
    .rowentitylist .list-row .isDefault {
      font-weight: normal;
      font-family: Arial, Helvetica, sans-serif;
      margin-right: 1em; }
    .rowentitylist .list-row .visibility {
      font-weight: normal;
      font-family: Arial, Helvetica, sans-serif; }
  .rowentitylist .list-row.disabled .list-name {
    text-decoration: line-through;
    color: #888; }
  .rowentitylist .list-isScrollingPlaceholder {
    color: #DDD;
    font-style: italic; }
  .rowentitylist div {
    outline: 0px; }
  .rowentitylist .rowentitylist-addaction:hover {
    background-color: transparent; }
  .rowentitylist .rowentitylist-addaction {
    cursor: pointer;
    margin-top: .5rem;
    margin-bottom: .5rem; }
    .rowentitylist .rowentitylist-addaction .rowentitylist__add {
      color: #F26522;
      margin-right: 0px; }
    .rowentitylist .rowentitylist-addaction .rowentitylist__add-text {
      color: #F26522; }
  .rowentitylist .tracklist-layout-badges {
    display: inline-block; }
    .rowentitylist .tracklist-layout-badges .simbadge img {
      max-height: 12px; }

.listv2 {
  outline: none; }
  .listv2 .listv2__loading {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bdbdbd; }
  .listv2 .listv2__nodata {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #bdbdbd; }
  .listv2 .listv2__error {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #c72222; }

.listv2__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 25px;
  height: 50px;
  color: #444; }
  .listv2__item .listv2__item__decorator {
    color: #F26522;
    position: absolute;
    left: 0px; }
  .listv2__item .listv2__item__image {
    display: inline-block;
    line-height: 42px;
    width: 40px;
    height: 40px;
    background: white;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    text-align: center;
    text-align: center;
    margin-right: 20px; }
  .listv2__item .listv2__item__content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis; }
    .listv2__item .listv2__item__content a {
      color: inherit;
      margin: 0em 0.3em; }
    .listv2__item .listv2__item__content a:hover,
    .listv2__item .listv2__item__content a:focus {
      color: #F26522; }
    .listv2__item .listv2__item__content .listv2__item__content__header {
      font-weight: bold;
      margin-bottom: 2px;
      font-size: 1em;
      line-height: 1.2em;
      max-height: 2.5em;
      overflow: hidden;
      display: flex; }
    .listv2__item .listv2__item__content .listv2__item__content__text {
      font-size: .8em;
      line-height: 1em;
      height: 1em;
      overflow: hidden;
      color: #444; }
      .listv2__item .listv2__item__content .listv2__item__content__text .listv2__item__content__text__value {
        margin-right: .5em; }
  .listv2__item .listv2__item__ending .listv2__item__ending__drag {
    cursor: row-resize; }

.listv2__item--clickable {
  cursor: pointer; }
  .listv2__item--clickable:hover {
    background: rgba(0, 0, 0, 0.1); }

.listv2__item--disabled {
  text-decoration: line-through;
  color: #888; }

.trackattack-subscription-list .subscription {
  display: flex;
  flex-direction: column;
  float: left;
  width: 10em;
  height: 16.1em;
  margin: .2em .4em;
  box-shadow: 4px 4px 12px -4px rgba(0, 0, 0, 0.5); }
  .trackattack-subscription-list .subscription:hover {
    box-shadow: 4px 4px 12px -4px black; }
  .trackattack-subscription-list .subscription h2.subscription__title {
    font-size: 1.2em;
    font-weight: 600;
    border-bottom: 1px solid lightgrey;
    text-align: center;
    padding: 1px 3px; }
  .trackattack-subscription-list .subscription .subscription__price {
    font-size: 120%;
    font-weight: 600;
    margin: .4em .4em 0em .4em;
    text-align: center; }
  .trackattack-subscription-list .subscription .subscription__price__period {
    font-size: 75%;
    padding: 1px 3px;
    text-align: center; }
  .trackattack-subscription-list .subscription .subscription__trial {
    font-size: 95%;
    padding: 1px 3px;
    margin-top: 1em;
    text-align: center; }
  .trackattack-subscription-list .subscription .subscription__spacer {
    flex: 1; }
  .trackattack-subscription-list .subscription .subscription__button {
    color: #fff;
    background-color: #F26522;
    text-align: center;
    font-size: 120%;
    padding: .1em; }

.rowentitylist .ReactVirtualized__Collection {
  min-height: 300px; }

.rowentitylist .gridItem {
  cursor: pointer;
  box-shadow: 4px 4px 12px -4px rgba(0, 0, 0, 0.5); }
  .rowentitylist .gridItem .gridItem-cover {
    height: 60%; }
    .rowentitylist .gridItem .gridItem-cover img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .rowentitylist .gridItem .gridItem-text {
    height: 40%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .rowentitylist .gridItem .gridItem-text .name {
      line-height: 1.1em;
      height: 2.2em;
      overflow: hidden;
      margin-top: .4em; }
    .rowentitylist .gridItem .gridItem-text .price {
      font-size: 110%;
      font-weight: 600;
      margin: .4em .4em .6em .4em; }
    .rowentitylist .gridItem .gridItem-text .raw-price {
      font-size: 100%;
      text-decoration: line-through;
      font-weight: 500;
      margin: .2em; }
    .rowentitylist .gridItem .gridItem-text .discount-price {
      font-size: 130%;
      color: red;
      font-weight: 600;
      margin: .4em; }
    .rowentitylist .gridItem .gridItem-text .subscription-mark {
      font-size: 85%;
      color: #F26522;
      font-weight: 600;
      margin: .4em; }
    .rowentitylist .gridItem .gridItem-text .btn {
      margin-bottom: .5em; }

.rowentitylist .gridItem:hover {
  box-shadow: 4px 4px 12px -4px black; }

.landing-root .landing-fetching {
  padding: .5rem;
  margin-bottom: .5rem;
  background-color: lightyellow; }

.landing-root .landing-error {
  padding: .5rem;
  margin-bottom: .5rem;
  background-color: lightcoral;
  color: #fff; }

.landing-root .container-content-wrap {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  padding: 0px; }

.landing-root .container-main {
  margin-top: -3px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .landing-root .mobile-only {
    display: none; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .landing-root .mobile-only {
    display: none; } }

@media only screen and (min-width: 1200px) {
  .landing-root .mobile-only {
    display: none; } }

.landing-root #top-banner {
  position: relative; }
  @media only screen and (max-width: 768px) {
    .landing-root #top-banner {
      display: none; } }
  .landing-root #top-banner .landing-top-banner-image-container {
    position: absolute;
    width: 100%; }
  .landing-root #top-banner .landing-top-banner-dim-foreground {
    background-color: #2B3137;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: absolute; }
  .landing-root #top-banner .landing-top-banner-container {
    position: absolute;
    padding-top: 140px;
    padding-bottom: 140px; }
    @media only screen and (min-width: 1200px) {
      .landing-root #top-banner .landing-top-banner-container {
        height: 750px; } }
  .landing-root #top-banner .landing-high-level-container {
    height: 100%;
    padding-right: 70px; }
  .landing-root #top-banner .landing-high-level-value-title {
    color: white;
    font-size: 70px;
    text-align: left;
    line-height: 1; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .landing-root #top-banner .landing-high-level-value-title {
        font-size: 4em; } }
  .landing-root #top-banner .landing-high-level-value-content {
    color: white;
    font-size: 30px;
    text-align: left;
    font-weight: 300; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .landing-root #top-banner .landing-high-level-value-content {
        font-size: 2em; } }
    .landing-root #top-banner .landing-high-level-value-content a {
      font-size: .9rem;
      color: #FFFFFF;
      text-decoration: underline;
      line-height: 1.2rem;
      display: block; }

.landing-root .landing-section {
  background-color: white;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 30px;
  border-top: solid #A0A0A0 1px;
  margin-left: 0px;
  margin-right: 0px; }
  @media only screen and (max-width: 768px) {
    .landing-root .landing-section {
      padding: 0px;
      padding-bottom: 1em; } }
  .landing-root .landing-section h2.landing-section__title {
    font-size: 60px;
    color: #606060;
    margin-top: 40px;
    margin-bottom: 32px;
    font-weight: bold;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .landing-root .landing-section h2.landing-section__title {
        font-size: 2em;
        margin-top: 1.5em;
        margin-bottom: 1em;
        padding-left: 1em;
        padding-right: 1em; } }
  .landing-root .landing-section h1.landing-section__title {
    font-size: 80px;
    color: #F26522;
    margin-top: 40px;
    margin-bottom: 32px;
    font-weight: bold;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .landing-root .landing-section h1.landing-section__title {
        font-size: 2.5em;
        margin-top: 1.5em;
        margin-bottom: 1em;
        padding-left: 1em;
        padding-right: 1em; } }
  .landing-root .landing-section .landing-section__img1 {
    height: 100px;
    margin-bottom: 40px; }
    @media only screen and (max-width: 768px) {
      .landing-root .landing-section .landing-section__img1 {
        max-width: 85%;
        margin: auto;
        height: auto;
        max-height: 6em;
        margin-bottom: 1em; } }
  .landing-root .landing-section .landing-section__img2 {
    max-width: 100%;
    height: 300px; }
    @media only screen and (max-width: 768px) {
      .landing-root .landing-section .landing-section__img2 {
        margin: auto;
        height: auto;
        margin-bottom: 1em; } }
  .landing-root .landing-section .landing-section__img-single {
    max-width: 100%;
    height: 300px; }
    @media only screen and (max-width: 768px) {
      .landing-root .landing-section .landing-section__img-single {
        max-width: 85%;
        margin: auto;
        height: auto;
        max-height: 6em;
        margin-bottom: 1em; } }
  .landing-root .landing-section .landing-section__text {
    font-size: 24px;
    color: #303030;
    text-align: left;
    margin-left: 20px; }
    @media only screen and (max-width: 768px) {
      .landing-root .landing-section .landing-section__text {
        font-size: 1em; } }

.landing-root .mobile-banner {
  padding-bottom: 2em !important; }

.landing__videocontainer {
  margin: 1em 0px;
  text-align: center; }
  .landing__videocontainer div {
    display: inline-block; }

.landing__imgcontainer {
  margin: 1em 0px;
  text-align: center; }
  .landing__imgcontainer img {
    max-width: 80%;
    margin: auto; }

.value-page-root {
  background-color: #FFFFFF; }
  .value-page-root h1 {
    color: #888;
    font-size: 3rem; }
  .value-page-root h2 {
    color: #888;
    font-size: 2rem; }
  .value-page-root .value-page-header {
    padding-top: 2rem;
    font-size: 1rem;
    margin-bottom: 1rem; }
    .value-page-root .value-page-header .value-page-header__download {
      width: 100%;
      text-align: center; }
      .value-page-root .value-page-header .value-page-header__download img {
        margin: auto; }
    .value-page-root .value-page-header .value-page-header__download-availablefor {
      margin-top: 1rem; }
    .value-page-root .value-page-header .value-page-header__subtitle {
      margin: 1.5rem 0rem; }
    .value-page-root .value-page-header .value-page-header__sign-up-link {
      width: 100%;
      display: block; }
      @media only screen and (max-width: 768px) {
        .value-page-root .value-page-header .value-page-header__sign-up-link {
          margin-bottom: 1.5rem;
          text-align: center; } }
    .value-page-root .value-page-header .value-page-header__download-title-image-container img {
      width: 85%;
      margin: auto; }
      @media only screen and (max-width: 768px) {
        .value-page-root .value-page-header .value-page-header__download-title-image-container img {
          width: 90%; } }
  .value-page-root .value-page-header_subtitle {
    border-top: solid #A0A0A0 1px;
    padding-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 175%; }
    @media only screen and (max-width: 768px) {
      .value-page-root .value-page-header_subtitle {
        font-size: 1.2rem;
        line-height: 150%; } }
    .value-page-root .value-page-header_subtitle .container {
      padding: 0rem 2rem;
      text-align: center; }
  .value-page-root .value-page-section {
    padding-top: 2rem;
    margin-bottom: 1rem; }
    .value-page-root .value-page-section .value-page-section__text {
      padding-top: 2rem;
      font-size: 1rem;
      line-height: 175%; }
    .value-page-root .value-page-section .value-page-section__img-container {
      width: 100%;
      text-align: right; }
      @media only screen and (max-width: 768px) {
        .value-page-root .value-page-section .value-page-section__img-container {
          display: none; } }
      .value-page-root .value-page-section .value-page-section__img-container img {
        width: 85%; }
    .value-page-root .value-page-section .value-page-section__img-container.odd {
      text-align: left; }
  .value-page-root .value-page-footer {
    text-align: center;
    padding: 2rem;
    background-color: #F26522; }
    .value-page-root .value-page-footer h2 {
      text-align: center;
      color: #fff;
      font-size: 1.75rem; }
    .value-page-root .value-page-footer button {
      color: #F26522;
      background-color: #FFFFFF;
      margin-top: 2rem;
      padding: 1rem;
      font-size: large; }
  @media only screen and (max-width: 768px) {
    .value-page-root .container,
    .value-page-root .row {
      margin-left: 0px;
      margin-right: 0px; } }

.mob-navigation-root {
  height: 100%;
  width: 100%;
  background-color: #F0F0F0;
  min-width: 66vw; }
  .mob-navigation-root .mob-navigation-user {
    padding: 1rem; }
    .mob-navigation-root .mob-navigation-user .mob-navigation-user_pic {
      display: inline-block;
      width: 7rem;
      height: 7rem;
      background-color: #222429; }
      .mob-navigation-root .mob-navigation-user .mob-navigation-user_pic .mob-navigation-user_initals {
        text-align: center;
        margin-top: 2.5rem;
        font-size: 2rem;
        color: #fff; }
    .mob-navigation-root .mob-navigation-user .mob-navigation-user_name {
      color: #F26522;
      padding-top: 0.2rem; }
  .mob-navigation-root .mob-navigation {
    margin-top: 1rem; }
    .mob-navigation-root .mob-navigation ul {
      margin: 0px;
      padding: 0px; }
      .mob-navigation-root .mob-navigation ul .mob-navigation-item {
        display: block;
        font-weight: 600;
        margin-bottom: 0.7rem;
        padding: 0.3rem; }
        .mob-navigation-root .mob-navigation ul .mob-navigation-item a.mob-navigation-link {
          color: #222429;
          display: block;
          padding-left: 2rem;
          padding-top: 0.3rem;
          padding-bottom: 0.3rem; }
      .mob-navigation-root .mob-navigation ul .mob-navigation-item-separator {
        border-bottom-color: #222429;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        height: 0px;
        margin: 0.1rem 1.1rem 0.4rem 1.1rem;
        display: block; }

.overlay-btn {
  color: #F26522;
  border: none;
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.42857143;
  text-align: center;
  vertical-align: top;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer; }

.overlay-btn-default {
  background-color: #fff; }

.plain-overlay-btn {
  border: none;
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
  text-align: center;
  vertical-align: top;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer; }

.plain-overlay-btn-default {
  background-color: #fff; }

.progressbar__container {
  overflow: hidden;
  text-align: left;
  line-height: 0px; }
  .progressbar__container .progressbar__simple {
    display: inline-block;
    background: #F26522;
    height: 3px; }

.section__title {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .section__title h2 {
    flex: 1; }
  .section__title .section__title__buttons {
    font-size: 1.5rem;
    line-height: 1.1rem; }
    .section__title .section__title__buttons a,
    .section__title .section__title__buttons button {
      margin-left: .6em; }
  .section__title .section__header__message {
    font-size: .95em;
    display: inline-block;
    margin-left: 1em;
    color: red; }
    .section__title .section__header__message .field-group {
      color: #888; }

.section__subtitle {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .section__subtitle h3 {
    flex: 1; }

.section__more {
  margin-top: 1rem;
  font-size: 80%;
  display: block;
  text-align: right; }

.settings-field-stub .settings-field-stub_text {
  padding-top: 7px; }

.settings-field-stub a.settings-field-stub_edit {
  color: #F26522;
  float: right;
  font-size: 1.5em;
  cursor: pointer; }

.settings-field-stub a.settings-field-stub_clear {
  color: #F26522;
  float: right;
  padding-top: 7px;
  cursor: pointer; }

.help-block {
  font-size: 90%; }

.settings-bottom-buttons {
  text-align: right;
  padding-right: 0px; }
  .settings-bottom-buttons .btn {
    min-width: 5rem; }

.overlay-initial-settings .dropzone-with-preview {
  margin-left: 14px;
  margin-bottom: 14px; }

.overlay-initial-settings .settings-bottom-buttons {
  padding-right: 1rem; }

.overlay-initial-settings p {
  padding: .5rem; }

.license .license__body {
  border-color: darkgrey;
  border-width: 1px;
  border-style: solid;
  padding: 4px;
  border-radius: 6px;
  background: lightgray;
  color: black; }

.license .license__reveal {
  font-size: 80%;
  margin-left: 1em; }

.tracks-filter-container {
  min-height: 1rem; }
  .tracks-filter-container label {
    margin-right: 3rem;
    float: left; }
    .tracks-filter-container label input {
      margin-left: 0.5 rem; }
  .tracks-filter-container .tracks-filter-stats {
    text-align: right; }
    .tracks-filter-container .tracks-filter-stats .tracks-filter-stats__item {
      display: inline-block; }

.tracks-container {
  text-align: center; }
  .tracks-container .track-official {
    color: #F26522;
    padding: 0.2rem 0.2rem 0.4rem 0.2rem; }
  .tracks-container .tracks-table thead th .table-header-sort {
    display: inline-block;
    margin-left: 0.5rem; }
  .tracks-container .tracks-table thead th .table-header-sort.asc {
    width: 0;
    height: 0;
    border-left: 0.35rem solid transparent;
    border-right: 0.35rem solid transparent;
    border-bottom: 0.7rem solid #fff; }
  .tracks-container .tracks-table thead th .table-header-sort.desc {
    width: 0;
    height: 0;
    border-left: 0.35rem solid transparent;
    border-right: 0.35rem solid transparent;
    border-top: 0.7rem solid #fff; }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .tracks-container .tracks-table thead th {
      display: none; } }
  @media only screen and (max-width: 479px) {
    .tracks-container .tracks-table thead th {
      display: none; } }
  .tracks-container .tracks-table td.tracks-table-cell-name {
    text-align: left; }
  .tracks-container .tracks-table td.tracks-mobile-cell-content .tracks-table-cell_track-type {
    font-weight: 600; }

.weatherwidget-root {
  color: #444;
  padding: 6px;
  overflow: hidden;
  font-family: sans-serif; }
  .weatherwidget-root .weatherwidget-loading {
    flex: 1;
    align-self: center; }
  .weatherwidget-root .weatherwidget-message {
    text-align: justify;
    margin-top: 0.7rem;
    color: #444;
    font-size: 0.9rem; }
  .weatherwidget-root .weatherwidget-poweredby {
    font-size: 70%;
    text-align: right; }
    .weatherwidget-root .weatherwidget-poweredby a {
      color: #444; }
  .weatherwidget-root .weatherwidget-current {
    display: flex;
    flex-direction: row;
    padding-top: 0.6rem; }
    .weatherwidget-root .weatherwidget-current .weatherwidget-main {
      display: flex;
      flex-direction: row;
      color: #444;
      font-size: 1.8rem; }
      .weatherwidget-root .weatherwidget-current .weatherwidget-main i {
        margin-left: 0.7rem;
        margin-right: 0.5rem;
        font-size: 2rem;
        padding-top: 0.6rem;
        margin-top: -0.5rem; }
    .weatherwidget-root .weatherwidget-current .weatherwidget-sub {
      display: flex;
      flex-direction: row;
      margin-left: 1rem;
      font-size: 1rem;
      margin-top: 0.4rem; }
      .weatherwidget-root .weatherwidget-current .weatherwidget-sub .weatherwidget-data-item {
        text-align: left;
        margin-left: 1rem; }
        .weatherwidget-root .weatherwidget-current .weatherwidget-sub .weatherwidget-data-item i {
          margin-right: 0.3rem; }
  .weatherwidget-root .weatherwidget-items-container {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    justify-content: space-around; }
    .weatherwidget-root .weatherwidget-items-container .weatherwidget-item {
      width: auto; }
      .weatherwidget-root .weatherwidget-items-container .weatherwidget-item .weatherwidget-item_wday {
        color: #5397BE; }
      .weatherwidget-root .weatherwidget-items-container .weatherwidget-item .weatherwidget-item_icon {
        color: #444;
        font-size: 1.5rem;
        margin-top: 0.5rem;
        margin-bottom: 0.4rem; }
      .weatherwidget-root .weatherwidget-items-container .weatherwidget-item .weatherwidget-item-main {
        color: #444; }
      .weatherwidget-root .weatherwidget-items-container .weatherwidget-item .weatherwidget-item-sub {
        color: #5397BE;
        font-size: 90%; }
        .weatherwidget-root .weatherwidget-items-container .weatherwidget-item .weatherwidget-item-sub i {
          margin-right: 3px;
          font-size: 0.8rem; }

.handleview {
  color: #444;
  display: inline-block;
  padding: 0.2rem;
  margin-right: 0.5rem; }
  .handleview .handleview__remove-btn {
    font-size: 0.9rem;
    padding-left: 0.3rem;
    cursor: pointer;
    font-weight: 800; }
  .handleview .handleview__avatar {
    height: 1rem;
    width: 1rem;
    margin-right: 0.2rem; }

.statslist-container {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around; }
  .statslist-container .stats-object {
    float: left;
    text-align: center;
    margin-left: 1rem; }
    .statslist-container .stats-object .stats-object__value {
      font-size: 250%; }
    .statslist-container .stats-object .stats-object__name {
      font-size: 90%; }

.statslist-container.tiled .stats-object {
  margin: .3rem;
  padding: .2rem;
  background: beige;
  height: auto; }

.track-edit-page-container {
  background-color: #F0F0F0;
  padding: 40px 10%;
  position: relative; }
  .track-edit-page-container input[type=number]::-webkit-inner-spin-button,
  .track-edit-page-container input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .track-edit-page-container h1 {
    padding: 0;
    margin: 0;
    color: black;
    font-weight: normal;
    font-size: 30px; }
  .track-edit-page-container h2 {
    padding: 20px 0 20px 10px;
    margin: 0;
    font-size: 24px;
    font-weight: normal;
    color: black; }
  .track-edit-page-container h3 {
    color: #F26522; }
  .track-edit-page-container .separator {
    height: 1px;
    background-color: #9B9B9B; }
  .track-edit-page-container .layout-title {
    background-color: white; }
  .track-edit-page-container .hcenter {
    text-align: center; }
  .track-edit-page-container .button-container {
    padding: 10px 0; }
  .track-edit-page-container .field-group-container {
    margin-bottom: 30px; }
  .track-edit-page-container .top-space {
    margin-top: 30px; }
  .track-edit-page-container .rightalign {
    text-align: right;
    margin-top: 6px; }
  .track-edit-page-container .no-padding {
    padding: 0px; }
  .track-edit-page-container .info-title {
    color: #202020;
    font-weight: normal; }
  .track-edit-page-container .info-input {
    margin-bottom: 30px;
    color: #202020; }
  .track-edit-page-container .nomargin {
    margin: 0px; }

.track-info-container {
  padding: 30px 0px 30px 0px; }
  .track-info-container .ql-editor {
    height: 300px;
    background-color: white;
    border: solid 1px lightgrey; }

.track-layout-info-container .Select {
  margin-bottom: 20px !important; }

.track-layout-info-container .Select-control {
  border: 1px solid lightgrey;
  border-radius: 4px; }

.track-layout-info-container .Select-clear-zone {
  display: none !important; }

.track-layout-info-container .Select-value {
  padding-right: 30px !important;
  padding-left: 10px !important; }

.track-layout-info-container .Select-value-label {
  color: black !important;
  font-size: 14px !important;
  line-height: 42px; }

.track-layout-info-container .Select-input {
  height: 42px; }

.track-layout-info-container .Select-placeholder {
  padding-top: 4px !important; }

.track-layout-info-container .no-margin {
  margin: 0px; }

.track-layout-info-container .info-title {
  font-weight: normal; }

.track-layout-info-container .smaller-input {
  margin-bottom: 30px;
  max-width: 200px; }

.track-layout-info-container .main-info-container {
  padding: 20px 10px 20px 10px; }

.track-layout-info-container .image-thumbnail {
  width: 200px;
  height: 200px; }

.track-layout-info-container .margin-bottom {
  margin-bottom: 20px; }

.track-layout-info-container .hcenter {
  text-align: center; }

.track-layout-info-container .location-title {
  margin-top: 6px; }

.layout-view-container {
  padding: 20px 10px 20px 10px; }
  .layout-view-container .title-value-row {
    padding-top: 20px; }
  .layout-view-container .title-label {
    color: black; }
  .layout-view-container .value-label {
    color: #808080; }
  .layout-view-container .title-pair-row {
    padding-top: 20px;
    margin-left: 0px;
    margin-right: 0px; }
    .layout-view-container .title-pair-row .pair-value-row {
      padding-left: 30px; }
  .layout-view-container .image-thumbnail {
    width: 200px;
    height: 200px; }

.track-operations-container {
  display: inline-block; }
  .track-operations-container .button-container {
    margin-left: 20px;
    margin-top: 20px;
    color: white;
    padding-left: 20px;
    padding-right: 20px; }

.toast-body {
  background: transparent;
  box-shadow: none; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .toast-root {
    width: 80%;
    left: 10%;
    margin-left: 0px;
    top: 42px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .toast-root {
    width: 80%;
    left: 10%;
    margin-left: 0px;
    top: 42px; } }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .toast-root {
    width: 80%;
    left: 10%;
    margin-left: 0px;
    top: 42px; } }

.toast-root .alert {
  display: flex;
  flex-direction: row;
  margin: 0px; }
  .toast-root .alert .toast-message {
    flex: 1; }
  .toast-root .alert .toast-close-btn {
    margin-left: 10px;
    cursor: pointer; }

.loader-container {
  position: absolute;
  top: 33%;
  bottom: 0px;
  left: 0px;
  right: 0px; }
  .loader-container .row {
    margin-right: 0px; }

.loader {
  margin: 100px auto;
  font-size: .5rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #f26522, 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.5), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.7), 1.8em -1.8em 0 0em #f26522, 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.5), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.7), 2.5em 0em 0 0em #f26522, 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.5), 2.5em 0em 0 0em rgba(242, 101, 34, 0.7), 1.75em 1.75em 0 0em #f26522, 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.5), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.7), 0em 2.5em 0 0em #f26522, -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.5), 0em 2.5em 0 0em rgba(242, 101, 34, 0.7), -1.8em 1.8em 0 0em #f26522, -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.5), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.7), -2.6em 0em 0 0em #f26522, -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.5), -2.6em 0em 0 0em rgba(242, 101, 34, 0.7), -1.8em -1.8em 0 0em #f26522; } }

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #f26522, 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.5), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.7), 1.8em -1.8em 0 0em #f26522, 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.5), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.7), 2.5em 0em 0 0em #f26522, 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.5), 2.5em 0em 0 0em rgba(242, 101, 34, 0.7), 1.75em 1.75em 0 0em #f26522, 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.5), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.7), 0em 2.5em 0 0em #f26522, -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.2), -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.5), 0em 2.5em 0 0em rgba(242, 101, 34, 0.7), -1.8em 1.8em 0 0em #f26522, -2.6em 0em 0 0em rgba(242, 101, 34, 0.2), -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.5), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.7), -2.6em 0em 0 0em #f26522, -1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(242, 101, 34, 0.2), 1.8em -1.8em 0 0em rgba(242, 101, 34, 0.2), 2.5em 0em 0 0em rgba(242, 101, 34, 0.2), 1.75em 1.75em 0 0em rgba(242, 101, 34, 0.2), 0em 2.5em 0 0em rgba(242, 101, 34, 0.2), -1.8em 1.8em 0 0em rgba(242, 101, 34, 0.5), -2.6em 0em 0 0em rgba(242, 101, 34, 0.7), -1.8em -1.8em 0 0em #f26522; } }

.infinite-progress-circle-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 20; }
  .infinite-progress-circle-container .infinite-progress-circle-background-dim {
    position: absolute;
    background-color: black;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px; }
  .infinite-progress-circle-container div > span > div {
    height: 100px !important;
    width: 100px !important; }
  .infinite-progress-circle-container .infinite-progress-circle {
    width: 100%;
    top: 300px;
    left: 50%;
    margin-left: -50px;
    position: absolute; }

ul.nav li a {
  color: #888; }

ul.nav li a:hover {
  color: #F26522; }

ul.nav li.active a,
ul.nav li.active a:focus,
ul.nav li.active a:hover {
  color: #F26522; }

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background: none; }

.search-remove {
  cursor: pointer; }

.search-form {
  float: right; }

.page-downloads h3,
.page-downloads h4 {
  text-align: center; }

.page-downloads .download-links {
  display: flex;
  flex-direction: row; }
  .page-downloads .download-links a {
    flex: 1;
    margin: 1rem;
    text-align: center;
    vertical-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px; }
  .page-downloads .download-links a:hover {
    border-color: #F26522; }
  .page-downloads .download-links .download-links__os {
    font-size: 2rem; }

.page-downloads .carousel-container .carousel {
  max-width: 60%;
  margin: auto; }

.leaderboard {
  min-height: 35em; }
  .leaderboard .leaderboard-interval__header,
  .leaderboard .leaderboard-interval {
    min-width: 3rem;
    font-size: .6rem;
    text-align: right;
    color: #337ab7;
    display: inline-block; }
  .leaderboard .lost {
    color: #c72222; }

.leaderboard__container .datesfilter {
  margin-top: -20px;
  margin-bottom: 20px; }

.welocomedialogwithtabs {
  width: 100%;
  height: 100%;
  padding: 5%;
  position: absolute;
  z-index: 10;
  background: rgba(150, 150, 150, 0.85); }
  .welocomedialogwithtabs .welocomedialogwithtabs-loading {
    text-align: center; }
    .welocomedialogwithtabs .welocomedialogwithtabs-loading span {
      padding: .5rem;
      display: inline-block; }
  .welocomedialogwithtabs .welocomedialogwithtabs-tabs h1 {
    width: 100%;
    text-align: center;
    color: #337ab7;
    margin-bottom: 1rem; }
  .welocomedialogwithtabs .welocomedialogwithtabs-tabs .tab-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-around; }
  .welocomedialogwithtabs .welocomedialogwithtabs-tabs .tab-thumb {
    text-align: center; }
    .welocomedialogwithtabs .welocomedialogwithtabs-tabs .tab-thumb .tab-title {
      width: 100%;
      text-align: center;
      font-size: 120%;
      margin-top: .3rem;
      font-weight: 600; }
    .welocomedialogwithtabs .welocomedialogwithtabs-tabs .tab-thumb .tab-title.active {
      color: #F26522; }
  .welocomedialogwithtabs .welocomedialogwithtabs-body {
    margin-top: 3rem; }
  .welocomedialogwithtabs .tab-bottombuttons .btn-toolbar {
    margin-top: .5rem; }
    .welocomedialogwithtabs .tab-bottombuttons .btn-toolbar button.btn {
      width: 5rem;
      float: right; }
  .welocomedialogwithtabs .tab-content_video {
    text-align: center; }
    .welocomedialogwithtabs .tab-content_video div {
      display: inline-block; }

.page-legal .page-legal-updated {
  font-size: 85%;
  text-align: right; }

.page-legal .page-legal-paragraph h4 {
  font-size: 100%;
  font-weight: bold; }

.page-legal .panel-title a {
  color: #222429; }

.barlaplist .lap:hover {
  background: lightgray; }

.barlaplist .lap {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .barlaplist .lap .lap-order {
    width: 4em;
    font-size: 120%;
    padding-left: .5em;
    font-weight: 600; }
    .barlaplist .lap .lap-order .lap-visibility {
      cursor: pointer;
      margin-right: .5em; }
  .barlaplist .lap .lap-time {
    width: 6em; }
  .barlaplist .lap .fast-lap {
    color: forestgreen; }
  .barlaplist .lap .slow-lap {
    color: red; }
  .barlaplist .lap .lap-bar {
    padding: .3em;
    flex: 1; }
    .barlaplist .lap .lap-bar span {
      width: 0%;
      height: 1em;
      display: block;
      background: gold;
      transition: width 0.75s ease-in-out, background 1s ease;
      border-collapse: collapse;
      border-radius: 3px; }
    .barlaplist .lap .lap-bar span.normal-lap {
      background: gold;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(0, 0, 0, 0.2) 100%); }
    .barlaplist .lap .lap-bar span.slow-lap {
      background: red;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(0, 0, 0, 0.2) 100%); }
    .barlaplist .lap .lap-bar span.fast-lap {
      background: forestgreen;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(0, 0, 0, 0.2) 100%); }
    .barlaplist .lap .lap-bar span.hidden-lap {
      background: grey;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(0, 0, 0, 0.2) 100%); }

.barlaplist hr {
  background: #888; }

.servicepage-container {
  position: absolute;
  top: 25%;
  bottom: 0px;
  left: 0px;
  right: 0px; }
  .servicepage-container .row {
    margin-right: 0px; }
  .servicepage-container .loading {
    text-align: center; }
    .servicepage-container .loading span div {
      display: inline-block; }
  .servicepage-container .page__logo {
    width: 100%;
    max-width: 420px;
    border-radius: 4px;
    padding: .3em; }
  .servicepage-container .servicepage-profile {
    margin-bottom: 1em; }
  .servicepage-container .buttons button {
    float: right;
    margin-left: 1em; }

.error__root .error__image {
  text-align: center;
  margin: auto; }
  .error__root .error__image img {
    max-width: 100%; }

.page-contacts .fa {
  width: 2em;
  text-align: center; }

.page-contacts .contacts-row {
  margin: .3em;
  font-size: 1.2em; }

#viewer .container-frame, #viewer .landing-root .landing-content, .landing-root #viewer .landing-content, #viewer .tracks-container,
#video-viewer .container-frame,
#video-viewer .landing-root .landing-content,
.landing-root #video-viewer .landing-content,
#video-viewer .tracks-container {
  margin: 3em 10em; }

#viewer #iframepdf,
#video-viewer #iframepdf {
  width: 100%;
  height: 85vh; }

#video-viewer {
  margin: auto;
  max-width: 66vw;
  margin-top: 3em;
  margin-bottom: 1em; }

#pdf-viewer .react-pdf__Page {
  margin: auto;
  margin-bottom: 1em; }
  #pdf-viewer .react-pdf__Page canvas {
    margin: auto; }

#viewer .panel {
  margin: 2em; }

#viewer .panel-default {
  max-width: 66vw;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em; }

#viewer .panel-body a.additional-file {
  display: block;
  margin: .5em 1em;
  padding: .3em;
  font-size: 1.1em;
  border-radius: 3px; }
  #viewer .panel-body a.additional-file .fa {
    font-size: 1.2em;
    margin-right: 1em; }

#viewer .panel-body a.additional-file:hover {
  background: rgba(0, 0, 0, 0.05); }

.page-pricing h2 {
  text-align: center; }

.page-pricing p {
  text-align: center; }

.page-pricing .yes {
  color: #267F00; }

.page-pricing .no {
  color: #c72222; }

.page-pricing .plan-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 3em;
  align-items: center; }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .page-pricing .plan-container {
      margin: auto;
      margin-top: 1em;
      margin-bottom: 1em; } }
  @media only screen and (max-width: 479px) {
    .page-pricing .plan-container {
      flex-direction: column;
      margin: auto;
      margin-top: 5px;
      margin-bottom: 5px; } }

.page-pricing .feature-title {
  font-size: 150%; }

.page-pricing .plan {
  border-radius: 5px;
  border: 2px solid lightgray;
  width: 18em;
  margin-bottom: 5px; }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .page-pricing .plan {
      width: 14em; } }
  .page-pricing .plan .plan-name {
    border-bottom: 2px solid lightgray;
    padding: .5em;
    font-size: 175%;
    margin: 0px;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    background: #222429;
    color: #fff; }
  .page-pricing .plan .plan-price {
    text-align: center;
    font-size: 300%;
    font-weight: 600; }
  .page-pricing .plan .plan-price__explanation {
    text-align: center;
    font-size: 80%;
    margin-top: -.8em;
    margin-bottom: .5em; }
  .page-pricing .plan .plan-description {
    padding: .2em .5em;
    margin: 0px;
    text-align: center;
    min-height: 9em; }
  .page-pricing .plan .plan-description__header {
    font-weight: bold; }
  .page-pricing .plan ul {
    list-style: none;
    padding: 0px; }
  .page-pricing .plan .plan-footer {
    border-top: 2px solid lightgray;
    padding: .2em .5em;
    margin: 0px;
    font-size: 150%;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    min-height: 2em; }

.simbadge {
  display: inline;
  margin-right: .5em; }
  .simbadge img {
    max-height: 18px; }

form .bottom-link {
  color: #F26522;
  font-size: 90%; }

form .form-group label {
  margin: .4em 0em;
  text-align: start; }

form .form-group .field-stub_text {
  margin: .4em 0em;
  text-align: start;
  margin-left: .5em; }

form .form-group a.field-stub_edit {
  color: #F26522;
  float: right;
  font-size: 1.5em;
  cursor: pointer; }

form .form-group a.field-stub_clear {
  color: #F26522;
  float: right;
  padding-top: 7px;
  cursor: pointer; }

form .help-block {
  font-size: 90%; }

form .btn {
  min-width: 5rem; }

form .bottom-buttons {
  text-align: right;
  padding-right: 0px; }
  form .bottom-buttons .btn:last-child {
    margin-left: 1em; }

form .cover-img {
  display: block;
  max-width: 85%;
  margin: auto; }

form .error {
  color: red; }

form .dropzone-fieldgroup {
  min-height: 3em;
  border-color: #F26522;
  border-width: 1px;
  border-style: dashed;
  border-radius: 5px;
  cursor: pointer; }
  form .dropzone-fieldgroup .dropzone-fieldgroup__invitation {
    color: #F26522;
    text-align: center;
    margin-top: .75em; }
  form .dropzone-fieldgroup .dropzone-fieldgroup__description {
    text-align: center;
    margin-top: .75em; }

form .form-preview {
  border: 1px solid black;
  padding: .2em;
  overflow: hidden; }
  form .form-preview canvas {
    margin: auto; }

.storeadmin .update-result {
  display: inline-block;
  margin-left: 1em;
  color: red; }

.ql-toolbar .ql-formats {
  padding: 2px;
  border: 1px solid;
  background: white; }

.ql-toolbar {
  background-color: lightgray;
  border-color: #080808; }

.fileview {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: .5em; }
  .fileview .fileview__icon {
    margin-right: 1em; }
  .fileview .fileview__text {
    overflow: hidden;
    flex: 1; }

.fileview__nofiles {
  width: 100%;
  text-align: center;
  font-size: 90%; }

.fileview__loading {
  width: 100%;
  text-align: center;
  font-size: 90%; }

.fileview__error {
  width: 100%;
  text-align: center;
  font-size: 90%;
  color: red; }

.fileview__dropzone {
  margin-top: 2em;
  min-height: 3em;
  border-color: #F26522;
  border-width: 1px;
  border-style: dashed;
  border-radius: 5px;
  cursor: pointer; }
  .fileview__dropzone .fileview__dropzone-content {
    color: #F26522;
    text-align: center;
    margin-top: .75em; }

.fileview__upload {
  margin-top: 2em; }

.webinar {
  text-align: center; }
  .webinar .btn {
    width: 15em;
    margin: 1em 0em; }
    @media only screen and (max-width: 768px) {
      .webinar .btn {
        display: none; } }
  .webinar #video-viewer {
    margin-top: 1em;
    margin-bottom: 1em; }
    @media only screen and (max-width: 768px) {
      .webinar #video-viewer {
        margin-top: 2em; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .webinar #video-viewer {
        margin-top: 3em; } }
  .webinar .webinar__container {
    text-align: left;
    background: #444;
    color: #444;
    width: auto;
    margin: 2em 1em; }
    .webinar .webinar__container .webinar__info {
      margin: 1.5em;
      text-align: left;
      padding: 2.5em 0em;
      border-width: 1px 0px;
      border-style: solid;
      border-color: #fff;
      color: #fff;
      line-height: 170%; }
      @media only screen and (min-width: 1200px) {
        .webinar .webinar__container .webinar__info {
          margin-top: 4em;
          margin-left: 4em; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .webinar .webinar__container .webinar__info {
          margin-top: 2em; } }
      @media only screen and (max-width: 768px) {
        .webinar .webinar__container .webinar__info {
          margin-top: 2em;
          text-align: center; } }
      @media only screen and (max-width: 768px) {
        .webinar .webinar__container .webinar__info .btn {
          display: inline-block; } }
      .webinar .webinar__container .webinar__info h3.webinar__title {
        font-size: 1rem;
        margin-left: 0px; }
    .webinar .webinar__container .webinar__free {
      text-align: center;
      font-size: 85%;
      margin-top: 1.5em; }
  .webinar .webinar__about {
    text-align: left; }
    .webinar .webinar__about h4 {
      font-size: 100%;
      font-weight: bold;
      color: #F26522;
      margin-bottom: 0px; }

.datesfilter {
  text-align: right; }
  .datesfilter .datesfilter__item {
    display: inline-block;
    margin: 2px 0px 0px 10px;
    font-size: 88%;
    cursor: pointer;
    color: #F26522; }
    .datesfilter .datesfilter__item span {
      padding: 2px 2px 0px 2px; }
  .datesfilter .datesfilter__item--selected {
    cursor: inherit;
    color: inherit;
    border: solid #888;
    border-width: 1px 1px 0px 1px;
    border-radius: 2px; }
  .datesfilter .datesfilter__customselector {
    position: absolute;
    text-align: left;
    right: 0%;
    z-index: 99; }
    .datesfilter .datesfilter__customselector .datesfilter__customselector__picker {
      display: flex; }

.page-leaderboards .leaderboard__container {
  min-height: 42em; }

.page-leaderboards .fa {
  margin-right: .5em; }

.newscard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  border-radius: 5px;
  margin: 3% 2%; }
  .newscard h2 {
    background: #e6e6e6;
    text-align: center;
    padding: 10px 0 10px 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.6em;
    line-height: 1.1em; }
  .newscard .newscard__date {
    background: #e6e6e6;
    text-align: center;
    margin: auto;
    padding-bottom: 3px;
    font-size: 90%;
    color: #444; }
  .newscard .newscard__img {
    width: 100%;
    margin: auto;
    height: 245px;
    object-fit: cover; }
  .newscard .newscard__text {
    margin: 0;
    padding: 4px 4px 4px 8px;
    color: #444;
    height: 5em;
    overflow: hidden;
    line-height: 1.6em; }

.newscard:hover {
  box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.7); }

.newspage .newspage__col__container {
  display: flex;
  flex-direction: row; }

.newspage .newspage__col {
  flex: 1; }

.newsarticle h1 {
  min-height: 2em;
  background: #e6e6e6;
  text-align: center;
  padding: 10px 0 10px 0;
  margin: 0; }

.newsarticle .newsarticle__date {
  background: #e6e6e6;
  text-align: center;
  margin: auto;
  padding-bottom: 3px;
  font-size: 90%;
  color: #444; }

.newsarticle .newsarticle__image {
  width: 100%;
  margin: auto;
  height: 350px;
  object-fit: cover; }

.newsarticle h3 {
  display: block;
  margin-top: 3em; }

.newsarticle article {
  margin-top: 2em; }
